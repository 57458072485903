import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import '@amzn/awsui-global-styles/polaris.css';
import DeviceLinks from './DeviceLinks';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { State } from '@hookstate/core';
import Systems from './Systems';
import Audit from './Audit';
import { TopNav } from './TopNav';
import SelectSite from './common/SelectSite';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../API";
import { listSIGInfraUserSitesV1, listUserSitesV1 } from 'src/graphql/queries';

export default function App(props: {forceAwakensState: State<ForceAwakensStateInterface>}) {

  const forceAwakensState = props.forceAwakensState;

  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);
  const [userSites, setUserSites] = useState<APIt.Site[]>([]);
  const [userSitesLoading, setUserSitesLoading] = useState<boolean>(false);

  const refreshUserSites = async (): Promise<APIt.Site[]> => {
    setUserSites([]);
    setUserSitesLoading(true);
    let stage = 'prod';
    try {
      const userSitesResponse = await API.graphql(graphqlOperation(listUserSitesV1,
        {
          // GLOBAL because all regions produce the same results
          path: `/FlexQuery/ForceAwakens_Segment_Lookup_V3/region/GLOBAL/stage/${stage === 'test' ? 'BETA' : stage.toUpperCase()}/?OutputFormat=json`
        })) as GraphQLResult<APIt.ListUserSitesV1Query>;
      let userSites = userSitesResponse.data?.listUserSitesV1 as APIt.Site[];
      const sigInfraUserSitesResponse = await API.graphql(graphqlOperation(listSIGInfraUserSitesV1,
        {
          username: props.forceAwakensState.get().username
        })) as GraphQLResult<APIt.ListSIGInfraUserSitesV1Query>;
      let filteredUserSites = userSites;
      if (!sigInfraUserSitesResponse.data?.listSIGInfraUserSitesV1?.allSites) {
        const sigInfraUserSites = sigInfraUserSitesResponse.data?.listSIGInfraUserSitesV1?.sites;
        filteredUserSites = userSites.filter(userSite => {
          const siteCode = userSite.SiteCode;
          return sigInfraUserSites?.includes(siteCode);
        });
      }
      setUserSites(filteredUserSites);
      setUserSitesLoading(false);
      return(filteredUserSites);
    } catch(error) {
      console.error(`refreshUserSites(): error is ${JSON.stringify(error)}`);
      setUserSitesLoading(false);
      throw error;
    }
    setUserSitesLoading(false);
  };

  const siteSelected = () => {
    setShowSelectSite(false);
  };

  useEffect(() => {
    console.log(`App() useEffect[darkMode]`);
    if (darkMode) {
      document.body.classList.add('awsui-polaris-dark-mode');
    } else {
      document.body.classList.remove('awsui-polaris-dark-mode');
    }
  }, [darkMode]);

  useEffect(() => {
    if (userSites.length === 0) refreshUserSites();
  }, [showSelectSite]);

  useEffect(() => {
    if (!forceAwakensState.value.selectedSite) setShowSelectSite(true);
    const init = async () => {
      const userSites = await refreshUserSites();
      let lastSelectedSite: { SegmentLocation: string; SegmentName: string; SegmentSource: string; siteCode: string } | null = null;
      const lastSelectedSiteFromLocalStorage = localStorage.getItem('lastSelectedSite');
      if (lastSelectedSiteFromLocalStorage) {
        try {
          lastSelectedSite = JSON.parse(lastSelectedSiteFromLocalStorage ?? '{}');
          if (lastSelectedSite?.SegmentLocation
            && lastSelectedSite?.SegmentName
            && lastSelectedSite?.SegmentSource
            && lastSelectedSite?.siteCode
            && userSites.filter(userSite => userSite.SiteCode === lastSelectedSite?.siteCode).length > 0)
          {
            props.forceAwakensState.selectedSite.set({
              SegmentLocation: lastSelectedSite.SegmentLocation,
              SegmentName: lastSelectedSite.SegmentName,
              SegmentSource: lastSelectedSite.SegmentSource,
              siteCode: lastSelectedSite.siteCode,
            });
            setShowSelectSite(false);
          }
        } catch(error) {
          console.error('App() useEffect()[] error:', error);
        }
      }
      const lastDarkMode = localStorage.getItem('darkMode');
      if (lastDarkMode) {
        setDarkMode(lastDarkMode === 'true');
      }
    }
    init();
  }, []);

  return (
    <>
      <div
        className="awsui"
        id='topNavigation'
        style={{position: 'sticky', top: 0, zIndex: 1002}}
      >
        <TopNav
          darkMode={darkMode}
          setDarkModeCallback={setDarkMode}
          setShowSelectSite={(v: boolean) => {
            setShowSelectSite(v);
          }}
          siteCode={forceAwakensState.get().selectedSite?.siteCode || ''}
          username={forceAwakensState.get().username || ''}
        />
      </div>
      {
        showSelectSite
        &&
        <SelectSite
          forceAwakensState={forceAwakensState}
          refreshUserSitesCallback={refreshUserSites}
          siteSelectedCallback={siteSelected}
          userSites={userSites}
          userSitesLoading={userSitesLoading}
        />
      }
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={(props) => <DeviceLinks {...props} forceAwakensState={forceAwakensState} />} />
          <Route path="/DeviceLinks" render={(props) => <DeviceLinks {...props} forceAwakensState={forceAwakensState} />} />
          <Route path="/Systems" render={(props) => <Systems {...props} forceAwakensState={forceAwakensState}/>} />
          <Route path="/Audit" render={(props) => <Audit {...props} forceAwakensState={forceAwakensState}/>} />
        </Switch>
      </BrowserRouter>
    </>
  );
}